import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgSteildach01 from "../../assets/leistungen/steildach02.jpg"
import imgSteildach02 from "../../assets/leistungen/steildach01.jpg"
import imgSteildach03 from "../../assets/leistungen/steildach03.jpg"
import imgSteildach04 from "../../assets/leistungen/steildach04.jpg"
import imgSteildach05 from "../../assets/leistungen/steildach05.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Steildacheindeckungen"
      Content="Ein Dach gehört zu den zentralen Elementen Ihres Hauses. Als&nbsp; 
      Spezialisten unterstützen wir Sie beim Neubau, bei umfassenden&nbsp; 
      Sanierungsmaßnahmen oder bei kleineren gestalterischen&nbsp; 
      Veränderungen. Dabei haben wir nicht nur das Dach im Blick,&nbsp; 
      sondern auch Ihre Vorstellungen in Sachen Gestaltung,&nbsp; 
      Energie-Effizienz und Budget."
    />
    <LeistungBox
      Color="gray"
      Image={imgSteildach02}
      IsImageLeft={true}
      Title="Tondachziegel und Betondachsteine"
      Content="Tondachziegel - Der Dachziegel aus natürlichem Ton ist ein&nbsp; 
        Baustoff mit langer Tradition. Die enorme Vielfalt an Formen und&nbsp; 
        Farben macht sie auch heute noch zu einem der beliebtesten&nbsp; 
        Dachbedeckungen. Vom klassischen naturrot bis zu modernen&nbsp; 
        Varianten mit matten oder glänzenden Oberflächen bietet der&nbsp; 
        Dachziegel eine Fülle an Gestaltungsmöglichkeiten.&nbsp; 
        <p />
        Betondachsteine - Der moderne Werkstoff ist extrem bruchfest und&nbsp; 
        frostbeständig. Oftmals sind diese Dachsteine mit einem speziellen&nbsp; 
        Mörtel beschichtet, das hält beispielsweise die Steildächer länger&nbsp; 
        sauber. Für eine hohe Sicherheit bei Sturm sorgt eine entsprechend&nbsp; 
        sturmsichere Verlegung."
    />
    <LeistungBox
      Color="white"
      Image={imgSteildach01}
      IsImageLeft={false}
      Title="Spenglerdach"
      Content="Die Dachbedeckung mit Metallscharen eignet sich besonders für moderne Architektur und ist verhältnismäßig leicht Sturm- und Frostsicher. Die Dächer werden üblicherweise aus Zink, Kupfer und Aluminium gefertigt."
    />
    <LeistungBox
      Color="gray"
      Image={imgSteildach03}
      IsImageLeft={true}
      Title="Bitumenschindeln"
      Content="Die Schindeln bestehen aus stabilen Glasvliesträgern mit einer&nbsp; 
        beidseitigen Bitumen Deckschicht. Sie sind leicht und bequem zu&nbsp; 
        verarbeiten. Außerdem sind sie durch die unterschiedlichen Formen&nbsp; 
        und Farben vielfältig einsetzbar. Granulate auf der Oberseite&nbsp; 
        machen die Schindel zudem wetterfest. Verwendet wird dieser&nbsp; 
        Werkstoff vor allem auf Carports, Gartenhäusern und Ferienhäusern."
    />
    <LeistungBox
      Color="white"
      Image={imgSteildach04}
      IsImageLeft={false}
      Title="Stahlprofilbleche"
      Content="Stahlprofilbleche kommen überwiegend auf Garagen, Carports und&nbsp; 
        industriellen Bauten zum Einsatz. Sie sind nicht nur leicht,&nbsp; 
        sondern können auch in fast alle RAL-Farben geliefert und in&nbsp; 
        unterschiedlichen Profilen, etwa in Wellen- oder Trapezform,&nbsp; 
        angefertigt werden."
    />
    <LeistungBox
      Color="gray"
      Image={imgSteildach05}
      IsImageLeft={true}
      Title="Wellplatten"
      Content="Für landwirtschaftliche Gebäude, Industriebauten, Schulen oder&nbsp; 
        große Wohnanlagen werden oftmals Wellplatten verwendet. Dieser&nbsp; 
        Baustoff überzeugt nicht nur durch seinen Preis, sondern ist&nbsp; 
        außerdem diffusionsoffen, feuchteregulierend, geräuschdämmend,&nbsp; 
        asbestfrei, nicht brennbar und beständig gegen Chemikalien. Diese&nbsp; 
        Variante der Bedachung bzw. für ein Steildach ist bereits ab&nbsp; 
        sieben Grad Dachneigung einsetzbar."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
